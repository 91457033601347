/* @import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

.container-game * {
  font-family: 'Press Start 2P', system-ui;
  font-weight: 400;
  font-style: normal;
}

.container-game {
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  margin: 0;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
}

.snake-canvas {
  background-color: #4e7039;
  border: 1px solid #122507;
}

.screen-frame {
  background-color: #4e7039;
  padding: 0;
  width: 380px;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.game-over {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #122507;
  color: #4e7039;
  padding: 20px;
  text-align: center;
}

.container-game p {
  color: #122507;
  font-size: 12px;
  line-height: 1em;
  margin: 0;
}

.container-game button {
  border: 0;
  outline: none;
  background: #4e7039;
  color: #122507;
  font-weight: 600;
  padding: 10px;
  border-radius: 4px;
}
