:root {
  --primary: #faac01;
  --secondary: #333;
  --light: #fff;
  --dark: #000;
  --toastify-color-dark: #222;
}
/* #47c8be #bfd048 */
* {
  box-sizing: border-box;
}

::-webkit-scrollbar-track {
  background-color: var(--secondary);
  border-radius: 4px;
}
::-webkit-scrollbar {
  width: 4px;
  background: var(--secondary);
  border-radius: 4px;
}
::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 4px;
}

::-moz-selection {
  /* Code for Firefox */
  color: var(--dark);
  background: var(--primary);
}

::selection {
  color: var(--dark);
  background: var(--primary);
}

body {
  margin: 0;
  font-family: 'Fredoka, sans-serif', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--dark);
  color: var(--light);
}

button {
  cursor: pointer;
}

main {
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  padding-bottom: 25px;
}

.copyright {
  text-align: right;
  font-family: monospace;
  font-size: 8px;
  position: absolute;
  z-index: 2;
  bottom: 2px;
  right: 5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.disabled input {
  opacity: 0.8;
  pointer-events: none;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.Mui-disabled {
  color: #555 !important;
}
.MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled
  .MuiOutlinedInput-notchedOutline {
  border-color: #555 !important;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-contained.Mui-disabled {
  color: #444 !important;
  background: #222 !important;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.Mui-disabled {
  color: #777 !important;
  border-color: #333 !important;
}
.Mui-disabled.MuiInputBase-input.MuiOutlinedInput-input {
  color: #555 !important;
  -webkit-text-fill-color: unset !important;
}
.MuiDialog-paper {
  background-color: var(--secondary) !important;
  color: var(--light) !important;
}
.MuiDialogContentText-root {
  color: var(--light) !important;
}
.MuiFormControlLabel-root.Mui-disabled.MuiFormControlLabel-labelPlacementEnd {
  filter: grayscale(1) sepia(2) contrast(0) !important;
}
